import Column from 'components/Column'
import Row from 'components/Row'
import { useAccount } from 'hooks/useAccount'
import styled, { useTheme } from 'lib/styled-components'
import { useEffect, useMemo, useState } from 'react'
import { Text } from 'ui/src/components/text/Text'

const InputWrapper = styled(Column)`
  position: relative;
  background-color: ${({ theme }) => theme.surface2};
  border-radius: 24px;
  padding: 24px;
  min-height: 800px;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  overflow: visible;
  gap: 16px;

  &:before {
    box-sizing: border-box;
    background-size: 100%;
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    border: 1px solid ${({ theme }) => theme.surface2};
  }
  &:hover:before {
    border-color: ${({ theme }) => theme.deprecated_stateOverlayHover};
  }
  &:focus-within:before {
    border-color: ${({ theme }) => theme.deprecated_stateOverlayPressed};
  }
`

const HeaderRow = styled(Row)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

function BridgeFormInner() {
  const { address } = useAccount()
  const [widgetInitialized, setWidgetInitialized] = useState(false)
  const theme = useTheme()

  const widgetStyles = useMemo(
    () => ({
      appBackground: theme.surface2,
      appAccentBg: theme.surface1,
      chartBg: theme.surface3,
      primary: theme.accent1,
      secondary: theme.accent2,
      badge: theme.accent1,
      borderColor: theme.surface3,
      borderRadius: 24,
      fontColor: theme.neutral1,
      fontColorAccent: theme.neutral2,
      fontFamily: 'Poppins, sans-serif',
    }),
    [theme],
  )

  const encodedStyles = useMemo(() => btoa(JSON.stringify(widgetStyles)), [widgetStyles])

  useEffect(() => {
    const loadScript = (src: string) => {
      return new Promise<void>((resolve, reject) => {
        const script = document.createElement('script')
        script.src = src
        script.async = true
        script.onload = () => resolve()
        script.onerror = () => reject(new Error(`Failed to load script ${src}`))
        document.body.appendChild(script)
      })
    }

    loadScript('https://app.debridge.finance/assets/scripts/widget.js')
      .then(() => {
        if (window.deBridge && !widgetInitialized) {
          window.deBridge.widget({
            element: 'debridgeWidget',
            v: '1',
            mode: 'deswap',
            width: '100%',
            height: '100%',
            address: address || '',
            title: ' ',
            lang: 'en',
            theme: theme.darkMode ? 'dark' : 'light',
            styles: encodedStyles,
          })
          setWidgetInitialized(true)
        }
      })
      .catch((error) => {
        console.error('Error loading deBridge script:', error)
      })
  }, [address, widgetInitialized, encodedStyles, theme.darkMode])

  return (
    <Column gap="xs" style={{ width: '100%', maxWidth: '100vw', minHeight: '800px', overflow: 'hidden' }}>
      <InputWrapper style={{ maxWidth: '100%', overflow: 'visible' }}>
        <HeaderRow>
          <Text variant="subheading2" color={theme.neutral2}>
            Bridge Assets
          </Text>
        </HeaderRow>
        <div
          id="debridgeWidget"
          style={{ width: '100%', height: '800px', maxWidth: '100vw', overflow: 'visible' }} /* Adjust height */
        ></div>
      </InputWrapper>
    </Column>
  )
}

export function BridgeForm() {
  return <BridgeFormInner />
}
