import { Path, Svg } from 'react-native-svg'

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { createIcon } from '../factories/createIcon'

export const [TimePast, AnimatedTimePast] = createIcon({
  name: 'TimePast',
  getIcon: (props) => (
    <Svg viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M8.66643 5.33325V7.72388L9.80445 8.8619C10.0651 9.12256 10.0651 9.54394 9.80445 9.80461C9.67445 9.93461 9.50376 9.99992 9.3331 9.99992C9.16243 9.99992 8.99174 9.93461 8.86174 9.80461L7.52841 8.47127C7.40307 8.34594 7.3331 8.17659 7.3331 7.99992V5.33325C7.3331 4.96525 7.6311 4.66659 7.99976 4.66659C8.36843 4.66659 8.66643 4.96525 8.66643 5.33325ZM8.01701 1.33325C6.23701 1.33325 4.56576 2.05187 3.33309 3.28654V1.99992C3.33309 1.63192 3.03509 1.33325 2.66643 1.33325C2.29776 1.33325 1.99976 1.63192 1.99976 1.99992L1.99976 4.66659C1.99976 5.03459 2.29776 5.33325 2.66643 5.33325L5.3331 5.33325C5.70176 5.33325 5.99976 5.03459 5.99976 4.66659C5.99976 4.29859 5.70176 3.99992 5.3331 3.99992H4.50709C5.46575 3.15125 6.70635 2.66659 8.01701 2.66659C10.9477 2.66659 13.3331 5.05925 13.3331 7.99992C13.3331 10.9406 10.9483 13.3333 8.01701 13.3333C5.98035 13.3333 4.15974 12.1993 3.26441 10.3733C3.10308 10.0426 2.70382 9.90529 2.37248 10.068C2.04182 10.23 1.9058 10.6292 2.0678 10.9599C3.18846 13.2465 5.46835 14.6666 8.01701 14.6666C11.683 14.6666 14.6664 11.6759 14.6664 7.99992C14.6664 4.32392 11.683 1.33325 8.01701 1.33325Z"
        fill="currentColor"
      />
    </Svg>
  ),
})
