import { ComponentProps } from 'react'

export function Sell(props: ComponentProps<'svg'>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.28516 11.9999C1.28516 6.08243 6.08194 1.28564 11.9994 1.28564C17.9169 1.28564 22.7137 6.08243 22.7137 11.9999C22.7137 17.9174 17.9169 22.7142 11.9994 22.7142C6.08194 22.7142 1.28516 17.9174 1.28516 11.9999ZM10.9816 7.49994C10.9816 6.9378 11.4373 6.48209 11.9995 6.48209C12.5616 6.48209 13.0173 6.9378 13.0173 7.49994V14.0426L15.7798 11.2802C16.1772 10.8827 16.8217 10.8827 17.2192 11.2802C17.6167 11.6777 17.6167 12.3222 17.2192 12.7197L12.7192 17.2197C12.3217 17.6172 11.6772 17.6172 11.2798 17.2197L6.77975 12.7197C6.38225 12.3222 6.38225 11.6777 6.77975 11.2802C7.17725 10.8827 7.82172 10.8827 8.21922 11.2802L10.9816 14.0426V7.49994Z"
        fill={props.fill || 'white'}
      />
    </svg>
  )
}
