import { SVGProps } from 'components/Logo/KswapIcon'
import styled from 'styled-components'

function Logo({ onClick }: { onClick?: () => void }) {
  return (
    <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>KibaSwap</title>
      <g id="K" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="KibaSwap" transform="translate(0.2031, 0.3525)" fill="#3B2C71" fill-rule="nonzero">
          <path
            d="M6 19.2453C6 15.2438 9.24382 12 13.2453 12H26V12.7547C26 16.7562 22.7562 20 18.7547 20H6V19.2453Z"
            fill="url(#paint0_linear_47_12330)"
          />
          <path
            d="M6 16.3461C6 13.68 7.32812 11.1892 9.54184 9.70346L24 0L24.1255 0.378896C25.2376 3.73784 24.0112 7.42843 21.1098 9.45358L6 20V16.3461Z"
            fill="url(#paint1_linear_47_12330)"
          />
          <path
            d="M26 15.6539C26 18.32 24.6719 20.8108 22.4582 22.2965L8 32L7.87454 31.6211C6.76236 28.2622 7.9888 24.5716 10.8902 22.5464L26 12V15.6539Z"
            fill="url(#paint2_linear_47_12330)"
          />
          <defs>
            <linearGradient id="paint0_linear_47_12330" x1="25" y1="12" x2="6.5" y2="12" gradientUnits="userSpaceOnUse">
              <stop stop-color="#A20027" />
              <stop offset="1" stop-color="#880128" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_47_12330"
              x1="6"
              y1="12"
              x2="25.9997"
              y2="11.929"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FF0801" />
              <stop offset="1" stop-color="#FFA800" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_47_12330"
              x1="26"
              y1="20"
              x2="6.00025"
              y2="20.071"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FC010F" />
              <stop offset="1" stop-color="#EA0161" />
            </linearGradient>
          </defs>
        </g>
      </g>
    </svg>
  )
}

const Container = styled.div<{ clickable?: boolean }>`
  position: relative;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
  display: flex;
  justify-content: center;
  align-items: center;
`

type NavIconProps = SVGProps & {
  clickable?: boolean
  onClick?: () => void
}

export const NavIcon = ({ clickable, onClick, ...props }: NavIconProps) => (
  <Container clickable={clickable}>
    <Logo onClick={onClick} />
  </Container>
)
