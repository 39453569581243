import { Trans } from 'i18n'
import React from 'react'
import { useHoneypotDisplayOption } from 'state/user/hooks'
import { HoneypotDisplayOption } from 'state/user/types'
import styled from 'styled-components'
import { ThemedText } from 'theme/components'

const Container = styled.div``

const Option = styled.div<{ isActive: boolean }>`
  width: auto;
  cursor: pointer;
  padding: 6px 12px;
  text-align: center;
  gap: 4px;
  border-radius: 12px;
  background: ${({ isActive, theme }) => (isActive ? theme.surface4 : 'transparent')};
  pointer-events: ${({ isActive }) => isActive && 'none'};
`

const Switch = styled.div`
  display: flex;
  width: auto;
  padding: 4px;
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 16px;
`

const HoneypotSettings: React.FC = () => {
  const [honeypotDisplayOption, setHoneypotDisplayOption] = useHoneypotDisplayOption()

  const handleOptionChange = (option: string | number) => {
    setHoneypotDisplayOption(option as HoneypotDisplayOption)
  }

  const options = [
    { value: 'none', display: <Trans i18nKey="settings.honeypotDisplayOption.none" /> },
    { value: 'input', display: <Trans i18nKey="settings.honeypotDisplayOption.input" /> },
    { value: 'output', display: <Trans i18nKey="settings.honeypotDisplayOption.output" /> },
    { value: 'both', display: <Trans i18nKey="settings.honeypotDisplayOption.both" /> },
  ]

  return (
    <Container>
      <ThemedText.DeprecatedBody fontWeight={600} marginBottom="8px" paddingTop="8px" paddingBottom="6px">
        <Trans i18nKey="settings.honeypotDisplayOption.title" />
      </ThemedText.DeprecatedBody>
      <Switch>
        {options.map((option) => (
          <Option
            key={option.value}
            isActive={honeypotDisplayOption === option.value}
            onClick={() => handleOptionChange(option.value)}
          >
            <ThemedText.BodyPrimary>{option.display}</ThemedText.BodyPrimary>
          </Option>
        ))}
      </Switch>
    </Container>
  )
}

export default HoneypotSettings
