export interface SerializedToken {
  chainId: number
  address: string
  decimals: number
  symbol?: string
  name?: string
}

export interface SerializedPair {
  token0: SerializedToken
  token1: SerializedToken
}

export enum SlippageTolerance {
  Auto = 'auto',
}

export const HoneypotDisplayOption = {
  NONE: 'none',
  INPUT: 'input',
  OUTPUT: 'output',
  BOTH: 'both',
} as const

export type HoneypotDisplayOption = typeof HoneypotDisplayOption[keyof typeof HoneypotDisplayOption]

