import Column from 'components/Column'
import { CollapsedIcon } from 'components/Icons/Collapse'
import { ExpandIcon } from 'components/Icons/Expand'
import Row, { AutoRow } from 'components/Row'
import ConnectionErrorView from 'components/WalletModal/ConnectionErrorView'
import { Option } from 'components/WalletModal/Option'
import PrivacyPolicyNotice from 'components/WalletModal/PrivacyPolicyNotice'
import { useOrderedConnections } from 'components/WalletModal/useOrderedConnections'
import styled, { css } from 'lib/styled-components'
import { ThemedText } from 'theme/components'
import { flexColumnNoWrap } from 'theme/styles'

const Wrapper = styled.div`
  ${flexColumnNoWrap};
  background-color: ${({ theme }) => theme.surface1};
  width: 100%;
  padding: 14px 16px 16px;
  flex: 1;
  gap: 16px;
`

const OptionGrid = styled.div<{ closed?: boolean }>`
  display: grid;
  flex: 1;
  grid-gap: 2px;
  border-radius: 12px;
  overflow: hidden;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    grid-template-columns: 1fr;
  `};
  opacity: ${({ closed }) => (closed ? 0 : 1)};
  max-height: ${({ closed }) => (closed ? 0 : '100vh')};
  transition: ${({ theme }) =>
    `max-height ${theme.transition.duration.fast} ${theme.transition.timing.inOut}, opacity ${theme.transition.duration.fast} ${theme.transition.timing.inOut}`};
`

const TextSectionWrapper = styled.div`
  padding: 0 4px;
`

const Line = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.surface3};
`

const OtherWalletIconStyles = css`
  height: 20px;
  width: 20px;
  fill: ${({ theme }) => theme.neutral2};
  flex-shrink: 0;
`

const StyledExpandIcon = styled(ExpandIcon)`
  ${OtherWalletIconStyles}
`

const StyledCollapsedIcon = styled(CollapsedIcon)`
  ${OtherWalletIconStyles}
`

export default function WalletModal({ openSettings }: { openSettings: () => void }) {
  const connectors = useOrderedConnections()

  return (
    <Wrapper data-testid="wallet-modal">
      <ConnectionErrorView />
      <AutoRow justify="space-between" width="100%">
        <ThemedText.SubHeader>Connect a wallet</ThemedText.SubHeader>
      </AutoRow>
      <Column gap="md" flex="1">
        <Row flex="1" align="flex-start">
          <OptionGrid data-testid="option-grid">
            {connectors.map((c) => (
              <Option connector={c} key={c.uid} />
            ))}
          </OptionGrid>
        </Row>
        <Column gap="md">
          <TextSectionWrapper>
            <PrivacyPolicyNotice />
          </TextSectionWrapper>
          <>
            <TextSectionWrapper>
              <ThemedText.Caption color="neutral3"></ThemedText.Caption>
            </TextSectionWrapper>
          </>
        </Column>
      </Column>
    </Wrapper>
  )
}
