
import axios from 'axios';

const HONEYPOT_API_URL = 'https://api.honeypot.is/v2/IsHoneypot';

export interface HoneypotDetails {
  token: {
    name: string;
    symbol: string;
    decimals: number;
    address: string;
    totalHolders: number;
    airdropSummary: {
      totalTxs: number;
      totalAmountWei: string;
      totalTransfers: number;
    };
  };
  withToken: {
    name: string;
    symbol: string;
    decimals: number;
    address: string;
    totalHolders: number;
  };
  summary: {
    risk: string;
    riskLevel: number;
    flags: string[];
  };
  simulationSuccess: boolean;
  honeypotResult: {
    isHoneypot: boolean;
  };
  simulationResult: {
    buyTax: number;
    sellTax: number;
    transferTax: number;
    buyGas: string;
    sellGas: string;
  };
  holderAnalysis: {
    holders: string;
    successful: string;
    failed: string;
    siphoned: string;
    averageTax: number;
    averageGas: number;
    highestTax: number;
    highTaxWallets: string;
    taxDistribution: Array<{
      tax: number;
      count: number;
    }>;
    snipersFailed: number;
    snipersSuccess: number;
  };
  flags: string[];
  contractCode: {
    openSource: boolean;
    rootOpenSource: boolean;
    isProxy: boolean;
    hasProxyCalls: boolean;
  };
  chain: {
    id: string;
    name: string;
    shortName: string;
    currency: string;
  };
  router: string;
  pair: {
    pair: {
      name: string;
      address: string;
      token0: string;
      token1: string;
      type: string;
    };
    chainId: string;
    reserves0: string;
    reserves1: string;
    liquidity: number;
    router: string;
    createdAtTimestamp: string;
    creationTxHash: string;
  };
  pairAddress: string;
}


const getHoneypotDetails = async (
  tokenAddress: string,
  chainID?: number,
  pair?: string,
  simulateLiquidity: boolean = false,
  forceSimulateLiquidity: boolean = false
): Promise<HoneypotDetails> => {
  try {
    const params = {
      address: tokenAddress,
      ...(chainID && { chainID }),
      ...(pair && { pair }),
      simulateLiquidity,
      forceSimulateLiquidity,
    };

    const response = await axios.get(HONEYPOT_API_URL, { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching honeypot details:', error);
    throw error;
  }
};

export default getHoneypotDetails;
