import { ReactComponent as OgIcon } from 'assets/svg/ogswap.svg'
import { ReactComponent as TelegramIcon } from 'assets/svg/telegram.svg'
import { ReactComponent as XIcon } from 'assets/svg/twitterx.svg'

import styled from 'styled-components'

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 60px;
  padding: 60px 0;
  background-color: ${({ theme }) => theme.surface1};
  position: fixed;
  bottom: 0;
  z-index: 1000; /* Ensure it stays on top */

  a {
    display: inline-block;
  }

  svg {
    width: 40px;
    height: 40px;
    cursor: pointer;
    fill: ${({ theme }) => theme.neutral1};
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.1);
    }
  }
`

export default function AppFooter() {
  return (
    <FooterContainer>
      <a href="https://x.com/kiba_swap" target="_blank" rel="noopener noreferrer">
        <XIcon />
      </a>
      <a href="https://t.me/kibaswapsupport" target="_blank" rel="noopener noreferrer">
        <TelegramIcon />
      </a>
      <a href="https://og.kibainu.com" target="_blank" rel="noopener noreferrer">
        <OgIcon />
      </a>
    </FooterContainer>
  )
}
