import { Protocol } from '@uniswap/router-sdk'
import Column from 'components/Column'
import { RowBetween } from 'components/Row'
import Toggle from 'components/Toggle'
import { Trans, t } from 'i18n'
import { atom, useAtom } from 'jotai'
import styled from 'lib/styled-components'
import { ReactNode, useCallback } from 'react'
import { RouterPreference } from 'state/routing/types'
import { ThemedText } from 'theme/components'

const LabelWrapper = styled(Column)`
  height: 100%;
  justify-content: center;
`

interface RoutingPreference {
  router: RouterPreference
  protocols: Protocol[]
}

enum RoutePreferenceOption {
  Optimal = 'Optimal',
  v3 = 'v3',
  v2 = 'v2',
}

type RoutePreferenceOptionsType =
  | {
      [RoutePreferenceOption.Optimal]: false
      [RoutePreferenceOption.v3]: boolean
      [RoutePreferenceOption.v2]: boolean
    }
  | {
      [RoutePreferenceOption.Optimal]: true
      [RoutePreferenceOption.v3]: false
      [RoutePreferenceOption.v2]: false
    }

const DEFAULT_ROUTE_PREFERENCE_OPTIONS: RoutePreferenceOptionsType = {
  [RoutePreferenceOption.Optimal]: true,
  [RoutePreferenceOption.v3]: false,
  [RoutePreferenceOption.v2]: false,
}

const DEFAULT_ROUTING_PREFERENCE: RoutingPreference = {
  router: RouterPreference.API,
  protocols: [Protocol.V2, Protocol.V3, Protocol.MIXED],
}

export const routingPreferencesAtom = atom(DEFAULT_ROUTING_PREFERENCE)
const routePreferenceOptionsAtom = atom<RoutePreferenceOptionsType>(DEFAULT_ROUTE_PREFERENCE_OPTIONS)

const ROUTE_PREFERENCE_TO_LABEL: Record<RoutePreferenceOption, ReactNode> = {
  [RoutePreferenceOption.Optimal]: t('common.defaultTradeOptions'),
  [RoutePreferenceOption.v3]: t('pool.v3'),
  [RoutePreferenceOption.v2]: t('pool.v2'),
}

function RoutePreferenceToggle({
  preference,
  isActive,
  text,
  subheading,
  disabled,
  toggle,
}: {
  preference: RoutePreferenceOption
  isActive: boolean
  text?: ReactNode
  subheading?: ReactNode
  disabled?: boolean
  toggle: () => void
}) {
  return (
    <RowBetween gap="md" padding="2px 0px" align="start">
      <LabelWrapper gap="xs">
        <ThemedText.BodyPrimary>{ROUTE_PREFERENCE_TO_LABEL[preference]}</ThemedText.BodyPrimary>
        {text && <ThemedText.BodySmall color="neutral2">{text}</ThemedText.BodySmall>}
        {subheading && <ThemedText.BodySmall color="neutral2">{subheading}</ThemedText.BodySmall>}
      </LabelWrapper>
      <Toggle id={`route-preference-toggle-${preference}`} isActive={isActive} disabled={disabled} toggle={toggle} />
    </RowBetween>
  )
}

export default function MultipleRoutingOptions({ chainId }: { chainId?: number }) {
  const [routePreferenceOptions, setRoutePreferenceOptions] = useAtom(routePreferenceOptionsAtom)
  const [, setRoutingPreferences] = useAtom(routingPreferencesAtom)
  const shouldDisableProtocolOptionToggle =
    !routePreferenceOptions[RoutePreferenceOption.v2] || !routePreferenceOptions[RoutePreferenceOption.v3]

  const handleSetRoutePreferenceOptions = useCallback(
    (options: RoutePreferenceOptionsType) => {
      if (options[RoutePreferenceOption.Optimal]) {
        setRoutePreferenceOptions(options)
        setRoutingPreferences({
          router: RouterPreference.API,
          protocols: [Protocol.V2, Protocol.V3, Protocol.MIXED],
        })
        return
      }

      const routingPreferences: RoutingPreference = {
        router: RouterPreference.API,
        protocols: [],
      }

      if (options[RoutePreferenceOption.v2] && options[RoutePreferenceOption.v3]) {
        routingPreferences.protocols = [Protocol.V2, Protocol.V3, Protocol.MIXED]
      } else if (options[RoutePreferenceOption.v2]) {
        routingPreferences.protocols = [Protocol.V2]
      } else if (options[RoutePreferenceOption.v3]) {
        routingPreferences.protocols = [Protocol.V3]
      }

      setRoutePreferenceOptions(options)
      setRoutingPreferences(routingPreferences)
    },
    [setRoutePreferenceOptions, setRoutingPreferences],
  )

  const handleRoutePreferenceToggle = useCallback(
    (toggledPreferenceOption: RoutePreferenceOption) => {
      if (toggledPreferenceOption === RoutePreferenceOption.Optimal) {
        routePreferenceOptions[RoutePreferenceOption.Optimal]
          ? handleSetRoutePreferenceOptions({
              [RoutePreferenceOption.Optimal]: false,
              [RoutePreferenceOption.v2]: true,
              [RoutePreferenceOption.v3]: true,
            })
          : handleSetRoutePreferenceOptions({
              [RoutePreferenceOption.Optimal]: true,
              [RoutePreferenceOption.v2]: false,
              [RoutePreferenceOption.v3]: false,
            })
        return
      }

      handleSetRoutePreferenceOptions({
        ...routePreferenceOptions,
        [toggledPreferenceOption]: !routePreferenceOptions[toggledPreferenceOption],
      })
    },
    [handleSetRoutePreferenceOptions, routePreferenceOptions],
  )

  return (
    <Column gap="sm">
      <RoutePreferenceToggle
        preference={RoutePreferenceOption.Optimal}
        isActive={routePreferenceOptions[RoutePreferenceOption.Optimal]}
        text={<Trans i18nKey="routing.cheapest" />}
        toggle={() => handleRoutePreferenceToggle(RoutePreferenceOption.Optimal)}
      />
      {!routePreferenceOptions[RoutePreferenceOption.Optimal] &&
        [RoutePreferenceOption.v3, RoutePreferenceOption.v2].map((preference) => (
          <RoutePreferenceToggle
            key={preference}
            preference={preference}
            isActive={routePreferenceOptions[preference]}
            disabled={routePreferenceOptions[preference] && shouldDisableProtocolOptionToggle}
            toggle={() => handleRoutePreferenceToggle(preference)}
          />
        ))}
    </Column>
  )
}
